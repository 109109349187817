export const environment = {
  production: false,
  mode:'QA',
  treebuLedger: 'https://qa.treebucapital.com/api/treebu-ledger/v1',

  // Servers
  treebuHasura: 'https://qa.treebucapital.com',
  treebuCapital: 'https://qa.treebucapital.com',
  treebuCrypto: 'https://qa.treebucapital.com',
  prefixEncrypted: 'api/ms-encrypted/v1/Encrypted',
  prefixTreebuHasura: 'api/rest',
  prefixTreeCrypto: 'api/ms-crypto/v1',
  prefixTreeLedger: 'api/treebu-ledger/v1',
  prefixBasic: 'api',
  prefixTreebuLedgerUnauthenticated : 'treebu-ledger-unauthenticated/v1',

  // Media Files
  mediaSource: '/assets/images',

  // Firebase
  firebaseConfig: {
    apiKey: "AIzaSyAOVWwKVMu3gEwQwTzfapvazIJdUFsoC-E",
    authDomain: "treebu-qa-d04a3.firebaseapp.com",
    projectId: "treebu-qa",
    storageBucket: "treebu-qa.appspot.com",
    messagingSenderId: "878560258914",
    appId: "1:878560258914:web:1518a730a682236c015a3b",
    measurementId: "G-1SSJKNFSYD"
  },

  timerRefreshToken : 3300 // seconds
}
